.App {
  text-align: left;
  color: #fff;
  background-color: #222;
}

.App-header {
  display: flex;
  flex-direction: column;
  color: white;
  background-image: url(./assets/images/banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 10vh;
  transition: all 500ms ease;
}

.App-header.frontpage {
  min-height: 80vh;
  background-position: right bottom;
}

.App-main {
  min-height: 80vh;
}

.App-main.frontpage {
  min-height: 40vh;
}

@media (max-width: 800px) {
  .App-header {
    background-image: url(./assets/images/banner-small.jpg);
  }
}